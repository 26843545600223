import React from 'react'
import Layout from '../components/new-password'
import { Head } from '../components/shared/elements'

export default (props) => {
  return (
    <>
      <Head />
      <Layout {...props} />
    </>
  )
}
