import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { parse } from 'query-string'
import { Header } from '../shared/elements'
import Form from './Form'
import * as API from '../../services/api'
import { checkLogin } from '../../services/auth'

export default function NewPassword(props) {
  const query = parse(props.location.search)

  const [showForm, setShowForm] = useState(false)

  const afterSubmit = () => navigate('/')

  useEffect(() => {
    const authenticated = checkLogin()
    if (authenticated) {
      afterSubmit()
    }

    if (showForm === false) {
      API.postRequest('/advertiser/new-password', { token: query.token })
        .then((response) => {
          if (response?.data?.success) {
            setShowForm(true)
          } else {
            afterSubmit()
          }
        })
        .catch((error) => {
          console.error(error)
          afterSubmit()
        })
    }
  })

  return (
    <>
      <Header />
      {showForm && <Form token={query.token} />}
    </>
  )
}
